





import { Vue, Component } from 'vue-property-decorator'
import { getView } from '@/utils/helpers';
@Component({
	components: {
		AdvancedChartWrapper: () => getView('ChartIQContainer'),
	}
})
export default class App extends Vue {}
